import React from "react";

export const Header = () => {
    const logout = () => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userEmail");
        window.location.href = "/"; // Redirect to login page after logout
    };

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item d-none d-sm-inline-block">
                    <div href="index3.html" className="nav-link">
                        Home
                    </div>
                </li>
                {/* <li className="nav-item d-none d-sm-inline-block">
                    <div className="nav-link">Contact</div>
                </li> */}
            </ul>

            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <div
                        className="nav-link"
                        data-widget="pushmenu"
                        role="button"
                    >
                        <i className="fas fa-bars"></i>
                    </div>
                </li>
                <li className="nav-item">
                    <div
                        className="nav-link"
                        data-widget="fullscreen"
                        role="button"
                    >
                        <i className="fas fa-expand-arrows-alt"></i>
                    </div>
                </li>
                <li className="nav-item">
                    <div
                        className="nav-link"
                        data-widget="navbar-search"
                        role="button"
                    >
                        <i className="fas fa-search"></i>
                    </div>
                    <div className="navbar-search-block">
                        <form className="form-inline">
                            <div className="input-group input-group-sm">
                                <input
                                    className="form-control form-control-navbar"
                                    type="search"
                                    placeholder="Search"
                                    aria-label="Search"
                                />
                                <div className="input-group-append">
                                    <button
                                        className="btn btn-navbar"
                                        type="submit"
                                    >
                                        <i className="fas fa-search"></i>
                                    </button>
                                    <button
                                        className="btn btn-navbar"
                                        type="button"
                                        data-widget="navbar-search"
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li>

                <li className="nav-item" onClick={logout}>
                    <div className="nav-link" role="button">
                        <i className="fas fa-arrow-right"></i> Logout
                    </div>
                </li>
            </ul>
        </nav>
    );
};
