import React from "react";
import { Header } from "./Header";
import { Menu } from "./Menu";
import { NewAgentComponent } from "../Components/NewAgentComponent";
import { Footer } from "./Footer";

export const AddAgent = () => {
    return (
        <div className="wrapper">
            <Header />
            <Menu />
            <NewAgentComponent />
            <Footer />
        </div>
    );
};
