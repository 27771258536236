import React from "react";
import { Header } from "./Header";
import { Menu } from "./Menu";
//import { Agentdetails } from "../Components/Agentdetails";
import { AgentDetailsComponent } from "../Components/AgentDetailsComponent";
import { Footer } from "./Footer";

export const AgentProfile = () => {
    return (
        <div className="wrapper">
            <Header />
            <Menu />
            <AgentDetailsComponent />
            <Footer />
        </div>
    );
};
