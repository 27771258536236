import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        padding: 30,
        paddingBottom: 100, // Ensure space for footer
    },

    header: {
        marginBottom: 30,
        borderBottom: 2,
        borderColor: "#333333",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: 5,
    },
    logo: {
        width: 100,
        height: 50,
    },

    companyAddress: {
        fontSize: 8,
        fontWeight: "bold",
        color: "#333333",
        marginBottom: 1,
    },
    flexend: {
        alignSelf: "flex-end",
    },

    title: {
        fontSize: 18,
        marginBottom: 10,
        color: "#333333",
        textAlign: "center",
    },
    receiptInfo: {
        marginBottom: 10,
        flexDirection: "row",
        justifyContent: "space-between",
    },
    noteInfo: {
        marginBottom: 10,
        marginTop: 10,
        flexDirection: "row",
        justifyContent: "space-between",
    },
    policy: {
        marginTop: 180,
    },
    info: {
        fontSize: 8,
        color: "#333333",
    },

    strong: {
        fontSize: 14,
    },
    receiptDate: {
        fontSize: 8,
        alignSelf: "flex-end",
    },
    section: {
        marginBottom: 10,
        marginTop: 20,
    },
    maeginTop: {
        marginTop: 20,
    },
    passenger: {
        marginBottom: 10,
        marginTop: 30,
    },
    item: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 10,
    },
    itemHead: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 20,
    },
    itemCommonHead30: {
        width: "30%",
        color: "#333333",
        fontSize: 10,
        backgroundColor: "#f0f8ff",
        fontWeight: "bold",
        padding: 5,
    },
    itemCommonHead10: {
        width: "10%",
        color: "#333333",
        fontSize: 10,
        backgroundColor: "#f0f8ff",
        fontWeight: "bold",
        padding: 5,
    },
    itemCommonHead15: {
        width: "15%",
        color: "#333333",
        fontSize: 10,
        backgroundColor: "#f0f8ff",
        fontWeight: "bold",
        padding: 5,
    },
    itemCommonHead20: {
        width: "20%",
        color: "#333333",
        fontSize: 10,
        backgroundColor: "#f0f8ff",
        fontWeight: "bold",
        padding: 5,
    },

    itemNoHead: {
        width: "15%",
        color: "#333333",
        fontSize: 10,
        backgroundColor: "#f0f8ff",
        fontWeight: "bold",
        padding: 5,
    },
    itemPurposeHead: {
        width: "25%",
        color: "#333333",
        fontSize: 10,
        backgroundColor: "#f0f8ff",
        fontWeight: "bold",
        padding: 5,
    },
    itemDateHead: {
        width: "30%",
        color: "#333333",
        fontSize: 10,
        backgroundColor: "#f0f8ff",
        fontWeight: "bold",
        padding: 5,
    },
    itemTransactionHead: {
        width: "30%",
        color: "#333333",
        fontSize: 10,
        backgroundColor: "#f0f8ff",
        fontWeight: "bold",
        padding: 5,
    },
    itemNo: {
        width: "15%",
        color: "#333333",
        fontSize: 10,
    },
    itemRow5: {
        width: "5%",
        color: "#333333",
        fontSize: 10,
    },
    itemRow10: {
        width: "10%",
        color: "#333333",
        fontSize: 10,
    },
    itemRow30: {
        width: "30%",
        color: "#333333",
        fontSize: 10,
    },
    itemRow20: {
        width: "20%",
        color: "#333333",
        fontSize: 10,
    },
    itemPurpose: {
        width: "25%",
        color: "#333333",
        fontSize: 10,
    },
    itemDate: {
        width: "30%",
        color: "#333333",
        fontSize: 10,
    },
    itemTotalAmount: {
        width: "10%",
        color: "#333333",
        fontSize: 10,
        textAlign: "right",
    },
    itemTotal: {
        width: "20%",
        marginLeft: 5,
        color: "#333333",
        fontSize: 10,
    },
    itemRecievedAmount: {
        width: "10%",
        color: "#333333",
        fontSize: 10,
        paddingTop: 10,
        paddingBottom: 20,
        borderBottom: 1,
        borderColor: "#333333",
        textAlign: "right",
    },
    itemRecieved: {
        width: "20%",
        color: "#333333",
        marginLeft: 5,
        fontSize: 10,
        paddingTop: 10,
        paddingBottom: 20,
        borderBottom: 1,
        borderColor: "#333333",
    },
    itemDueAmount: {
        width: "10%",
        color: "#333333",
        fontSize: 10,
        paddingTop: 5,
        paddingBottom: 20,
        textAlign: "right",
    },
    itemDue: {
        width: "20%",
        color: "#333333",
        fontSize: 10,
        marginLeft: 5,
        paddingTop: 5,
        paddingBottom: 20,
    },

    signInfo: {
        marginBottom: 10,
        marginTop: 100,
        marginRight: 30,
        flexDirection: "row",
        justifyContent: "space-between",
    },
    sign: {
        fontSize: 8,
        color: "#333333",
        paddingTop: 5,
        borderTop: 1,
        borderColor: "#333333",
    },
    footer: {
        borderTop: 2,
        borderColor: "#333333",
        position: "absolute",
        bottom: 0,
        left: 30,
        right: 30,
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: 20,
        paddingTop: 5,
        fontSize: 8,
    },
    pageNumber: {
        fontSize: 8,
    },
});

// Create Document Component
export const BMETListPDF = ({ Items }) => {
    let totalPages = 0;
    const formattedTimestamp = new Date().toLocaleString();

    return (
        <Document>
            <Page
                size="A4"
                style={styles.page}
                wrap
                render={({ pageNumber }) => {
                    totalPages = Math.max(totalPages, pageNumber);
                    return (
                        <>
                            <View style={styles.header}>
                                <Image
                                    style={styles.logo}
                                    src={require("./icon.jpg")}
                                />

                                <View style={styles.flexend}>
                                    <Text style={styles.companyAddress}>
                                        E-mail : info@separabd.com
                                    </Text>
                                    <Text style={styles.companyAddress}>
                                        Phone : 01798880111, 099534152
                                    </Text>
                                    <Text style={styles.companyAddress}>
                                        www.separabd.com
                                    </Text>
                                    <Text style={styles.companyAddress}>
                                        55/B Noakhali Tower (5th Floor), Purana
                                        Paltan, Dhaka-1000
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.receiptInfo}>
                                <View>
                                    <Text style={styles.info}>
                                        <Text style={styles.receiptDate}>
                                            <Text style={styles.info}>
                                                <Text style={styles.strong}>
                                                    BMET List
                                                </Text>
                                            </Text>
                                        </Text>
                                    </Text>
                                </View>
                                <View style={styles.flexend}>
                                    <Text style={styles.receiptDate}>
                                        {" "}
                                        Date: {formattedTimestamp}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.section}>
                                <View style={styles.itemHead}>
                                    <Text style={styles.itemCommonHead10}>
                                        T-Id
                                    </Text>
                                    <Text style={styles.itemCommonHead10}>
                                        Date
                                    </Text>
                                    <Text style={styles.itemCommonHead30}>
                                        Client
                                    </Text>
                                    <Text style={styles.itemCommonHead20}>
                                        Passport
                                    </Text>
                                    <Text style={styles.itemCommonHead20}>
                                        Agent
                                    </Text>
                                    <Text style={styles.itemCommonHead10}>
                                        Status
                                    </Text>
                                </View>
                                {Items.map((item, index) => (
                                    <View key={index} style={styles.item}>
                                        <Text style={styles.itemRow10}>
                                            {item.transactionId}
                                        </Text>
                                        <Text style={styles.itemRow10}>
                                            {item.date}
                                        </Text>
                                        <Text style={styles.itemRow30}>
                                            {item.clientName}
                                        </Text>
                                        <Text style={styles.itemRow20}>
                                            {item.passport}
                                        </Text>
                                        <Text style={styles.itemRow20}>
                                            {item.agentName}
                                        </Text>
                                        <Text style={styles.itemRow10}>
                                            {item.status === 1 ? "Com" : "Pen"}
                                        </Text>
                                    </View>
                                ))}
                            </View>

                            <View style={styles.footer}>
                                <View>
                                    <Text style={styles.companyAddress}>
                                        E-mail : info@separabd.com
                                    </Text>
                                    <Text style={styles.companyAddress}>
                                        Phone : 01798880111, 099534152
                                    </Text>
                                    <Text style={styles.companyAddress}>
                                        www.separaoverseas.com
                                    </Text>
                                    <Text style={styles.companyAddress}>
                                        55/B Noakhali Tower (5th Floor), Purana
                                        Paltan, Dhaka-1000
                                    </Text>
                                </View>

                                <View style={styles.flexend}>
                                    <Text style={styles.pageNumber}>
                                        Page {pageNumber} of {totalPages}
                                    </Text>
                                </View>
                            </View>
                        </>
                    );
                }}
            />
        </Document>
    );
};
