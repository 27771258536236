import { Link } from "react-router-dom";

function TableBody({ columns, items, onDelete, onDownload }) {
    return (
        <tbody>
            {items?.map((item, index) => {
                return (
                    <tr key={index}>
                        {columns?.map((column, id) => {
                            if (column.path) {
                                if (column.path !== "v-e-d") {
                                    if (column.path === "statusp-s") {
                                        if (item[column.path]) {
                                            return (
                                                <td key={`col-${id}`}>
                                                    <span className="p-1 bg-success rounded">
                                                        <span className="rounded">
                                                            Completed
                                                        </span>
                                                    </span>
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td key={`col-${id}`}>
                                                    <span className="p-1 bg-warning rounded">
                                                        <span className="rounded">
                                                            Pending
                                                        </span>
                                                    </span>
                                                </td>
                                            );
                                        }
                                    } else if (column.path === "statusa-b") {
                                        if (item[column.path]) {
                                            return (
                                                <td key={`col-${id}`}>
                                                    <span className="p-1 bg-danger rounded">
                                                        <span className="rounded">
                                                            Blocked
                                                        </span>
                                                    </span>
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td key={`col-${id}`}>
                                                    <span className="p-1 bg-success rounded">
                                                        <span className="rounded">
                                                            Active
                                                        </span>
                                                    </span>
                                                </td>
                                            );
                                        }
                                    } else if (column.path === "t-d-e-d") {
                                        return (
                                            <td key={`col-${id}`}>
                                                {/* <span
                                                    className="p-2 bg-info rounded"
                                                    onClick={() =>
                                                        onDownload(
                                                            item.transactionId
                                                        )
                                                    }
                                                >
                                                    <span className="rounded">
                                                        <i className="fas fa-download"></i>{" "}
                                                    </span>
                                                </span> */}
                                                <Link
                                                    to={`/view-transaction/${item.transactionId}`}
                                                    className="p-1"
                                                >
                                                    <span className="p-2 bg-secondary rounded">
                                                        <span className="rounded">
                                                            <i className="fas fa-eye"></i>{" "}
                                                        </span>
                                                    </span>
                                                </Link>

                                                <span
                                                    className="p-2 bg-danger rounded"
                                                    onClick={() =>
                                                        onDelete(
                                                            item.transactionId
                                                        )
                                                    }
                                                >
                                                    <span className="rounded">
                                                        <i className="fas fa-trash"></i>{" "}
                                                    </span>
                                                </span>
                                            </td>
                                        );
                                    } else if (column.path === "due-download") {
                                        return (
                                            <td key={`col-${id}`}>
                                                <Link
                                                    to={`/due-form/${item.agentID}`}
                                                    className="p-1"
                                                >
                                                    <span className="p-2 bg-secondary rounded">
                                                        <span className="rounded">
                                                            <i className="fas fa-eye"></i>{" "}
                                                        </span>
                                                    </span>
                                                </Link>
                                                {/* <span
                                                    className="p-2 bg-info rounded"
                                                    // onClick={() =>
                                                    //     on(item.agentID)
                                                    // }
                                                >
                                                    <span className="rounded">
                                                        <i className="fas fa-download"></i>{" "}
                                                    </span>
                                                </span> */}
                                            </td>
                                        );
                                    } else if (column.path === "e") {
                                        return (
                                            <td key={`col-${id}`}>
                                                <Link
                                                    to={`/update/${item.transactionId}`}
                                                    className="p-1"
                                                >
                                                    <span className="p-2 bg-secondary rounded">
                                                        <span className="rounded">
                                                            <i className="fas fa-edit"></i>{" "}
                                                        </span>
                                                    </span>
                                                </Link>
                                            </td>
                                        );
                                    } else {
                                        return (
                                            <td key={`col-${id}`}>
                                                {item[column.path]}
                                            </td>
                                        );
                                    }
                                } else {
                                    return (
                                        <td key={`col-${id}`}>
                                            <Link
                                                to={`/agent/${item.agentID}`}
                                                className="p-1"
                                            >
                                                <span className="p-2 bg-info rounded">
                                                    <span className="rounded">
                                                        <i className="fas fa-eye"></i>{" "}
                                                    </span>
                                                </span>
                                            </Link>
                                            <Link
                                                to={`/edit-agent/${item.agentID}`}
                                                className="p-1"
                                            >
                                                <span className="p-2 bg-secondary rounded">
                                                    <span className="rounded">
                                                        <i className="fas fa-edit"></i>{" "}
                                                    </span>
                                                </span>
                                            </Link>

                                            <span
                                                className="p-2 bg-danger rounded"
                                                onClick={() =>
                                                    onDelete(item.agentID)
                                                }
                                            >
                                                <span className="rounded">
                                                    <i className="fas fa-trash"></i>{" "}
                                                </span>
                                            </span>
                                        </td>
                                    );
                                }
                            }
                            return column.getContent(item, id);
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
}

export default TableBody;
