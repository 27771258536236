import React, { useState, useEffect } from "react";
import Pagination from "./TablePagination";
import TableLimit from "./TableLimit";
import MyTable from "./MyTable";
import _ from "lodash";
import { Axios } from "../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { DueListPDF } from "../PDFs/DueListPDF";

export const DuelistComponent = () => {
    const [sortColumn, setSortColumn] = useState("id");
    const [sortOrder, setSortOrder] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [searchText, setSearchText] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [agents, setAgents] = useState([]);

    const handleSearch = (searchQuery) => {
        setSearchText(searchQuery);
        setCurrentPage(1); // Move this outside of the filter function
    };

    useEffect(() => {
        const agents = async () => {
            try {
                const res = await Axios.get(`all-agents-due`);
                if (res.status === 200) {
                    setAgents(res.data);
                }
            } catch (err) {
                alert(err);
            }
        };
        agents();
        return () => {};
    }, []);

    useEffect(() => {
        const filteredAgents = agents.filter(
            (item) =>
                (item.agentName &&
                    item.agentName
                        .toLowerCase()
                        .includes(searchText.toLowerCase())) ||
                (item.agentID &&
                    item.agentID
                        .toString()
                        .toLowerCase()
                        .includes(searchText.toLowerCase()))
        );
        setFilteredData(filteredAgents);
    }, [agents, searchText]);

    const columns = [
        { name: "Agent ID", path: "agentID" },
        { name: "Agent Name", path: "agentName" },
        { name: "Phone", path: "phoneNo" },
        { name: "Due", path: "due" },
        { name: "Action", path: "due-download" },
    ];

    function onHandleSort(column, order) {
        setSortColumn(column);
        setSortOrder(order);
    }

    function resetAfterLimitChange(limit) {
        setLimit(limit);
        setCurrentPage(1);
    }

    function sortAgents() {
        const column = columns.find((column) => column.name === sortColumn);
        if (!column) return filteredData;

        const sortedAgents = _.orderBy(filteredData, column.path, sortOrder);
        return sortedAgents;
    }

    function paginateAgents() {
        const startIndex = (parseInt(currentPage) - 1) * parseInt(limit);
        const endIndex = parseInt(startIndex) + parseInt(limit);
        return sortAgents().slice(startIndex, endIndex);
    }

    const currentDate = new Date();

    const formattedDate = currentDate
        .toISOString()
        .replace(/[-:.]/g, "")
        .replace("T", "_")
        .slice(0, -5);

    const fileName = `DueList_${formattedDate}.pdf`;

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Due List</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li className="breadcrumb-item active">
                                    Due-list
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">
                    <div className="card bg-infocard card-info card-outline p-5">
                        <div className="row">
                            <div className="col-md-8 mb-3"></div>

                            <div className="col-md-2 mb-3">
                                <div
                                    className="input-group"
                                    //style={{ marginLeft: "auto", width: "50%" }}
                                >
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Search"
                                        value={searchText}
                                        onChange={(e) =>
                                            handleSearch(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-md-1 mb-3">
                                <TableLimit
                                    onChange={(limit) =>
                                        resetAfterLimitChange(limit)
                                    }
                                    options={[
                                        { name: "Five", value: 5 },
                                        { name: "Ten", value: 10 },
                                        { name: "Twenty", value: 20 },
                                    ]}
                                />
                            </div>
                            <div className="col-md-1 mb-3">
                                <PDFDownloadLink
                                    document={
                                        <DueListPDF
                                            Items={filteredData}
                                            tableName={"Due List"}
                                        />
                                    }
                                    fileName={fileName} // Use the fileName variable here
                                >
                                    {({ loading }) =>
                                        loading ? (
                                            "Loading..."
                                        ) : (
                                            <button className="btn btn-info form-control">
                                                <i className="fas fa-download"></i>
                                                Download
                                            </button>
                                        )
                                    }
                                </PDFDownloadLink>
                            </div>
                        </div>
                        <MyTable
                            columns={columns}
                            items={paginateAgents()}
                            sortColumn={sortColumn}
                            sortOrder={sortOrder}
                            onHandleSort={onHandleSort}
                        />
                        <Pagination
                            data={filteredData}
                            //data={transactions}
                            limit={limit}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};
