import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Button from "react-bootstrap/Button";
import { Axios } from "../config";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import AlertDismissible from "./Alert";
import { AgentDueListPDF } from "../PDFs/AgentDueListPDF";
import { Link } from "react-router-dom";

export function DueComponent() {
    const [due, setDue] = useState([]);
    const { id } = useParams(); // Get the id from the URL
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const fetchDueData = async () => {
            try {
                const res = await Axios.get(`search-for-due-form/${id}`);
                if (res.data) {
                    setDue(res.data);
                }
            } catch (error) {
                console.error("Error fetching due data:", error);
            }
        };
        fetchDueData();

        return () => {};
    }, [id]);

    const currentDate = new Date();

    // Format the current date as dd/mm/yyyy
    const formattedDate = currentDate.toLocaleDateString("en-GB");

    const formattedDated = currentDate
        .toISOString()
        .replace(/[-:.]/g, "")
        .replace("T", "_")
        .slice(0, -5);

    const fileName = `AgentDueList_${formattedDated}.pdf`;

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Due Form</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li className="breadcrumb-item active">
                                    due-form
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="card bg-infocard card-info card-outline p-5">
                        <div className=" mt-3 form-horizontal col-md-8 mx-auto card no-bg border-secondary p-3">
                            <h5 className="text-center">
                                {due.length ? due.length : "No"} Due Transaction
                                for {id}{" "}
                                {due.length ? (
                                    <PDFDownloadLink
                                        document={
                                            <AgentDueListPDF
                                                Items={due}
                                                id={id}
                                                tableName={"Due List"}
                                            />
                                        }
                                        fileName={fileName} // Use the fileName variable here
                                    >
                                        {({ loading }) =>
                                            loading ? (
                                                "Loading..."
                                            ) : (
                                                <button className="btn btn-info">
                                                    <i className="fas fa-download"></i>
                                                    Download
                                                </button>
                                            )
                                        }
                                    </PDFDownloadLink>
                                ) : (
                                    " "
                                )}
                            </h5>
                        </div>
                        {showAlert && (
                            <div className=" mt-3 row mx-auto card no-bg border-secondary p-3">
                                <AlertDismissible
                                    variant={"success"}
                                    alertMsg={"Record Updated successfully "}
                                />
                                <div>
                                    <Link to="/due-list">
                                        <Button className="btn btn-info form-control">
                                            <i className="fas fa-arrow-left"></i>{" "}
                                            Due List
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        )}

                        {due.length > 0 &&
                            due.map((item, index) => (
                                <Formik
                                    key={index}
                                    initialValues={{
                                        total: item.total || "",
                                        received: item.received || "",
                                        due: item.due || "",
                                        newAmount: "",
                                    }}
                                    validationSchema={Yup.object().shape({
                                        newAmount:
                                            Yup.string().required(
                                                "Amount is required"
                                            ),
                                    })}
                                    onSubmit={async (values, { resetForm }) => {
                                        const updatedValues = {
                                            ...values,
                                            transactionId: item.transactionId,
                                            agentId: item.agentName,
                                            updatedAt: formattedDate,
                                            receivedAmount: item.received,
                                            dueold: item.due,
                                        };

                                        try {
                                            const res = await Axios.post(
                                                `/update-due-amount`,
                                                updatedValues
                                            );

                                            if (res.data) {
                                                // Fetch the updated due data
                                                const updatedRes =
                                                    await Axios.get(
                                                        `search-for-due-form/${id}`
                                                    );
                                                if (updatedRes.data) {
                                                    // Update the state with the new due data
                                                    setShowAlert(true);
                                                    resetForm();
                                                }
                                            }
                                        } catch (error) {
                                            console.error(
                                                "Error submitting value:",
                                                error
                                            );
                                        }
                                    }}
                                >
                                    {({ handleSubmit }) => (
                                        <Form
                                            className="mt-3 form-horizontal col-md-8 mx-auto card no-bg border-secondary p-3"
                                            onSubmit={handleSubmit}
                                        >
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h3>
                                                                    <button>
                                                                        {index +
                                                                            1}
                                                                    </button>{" "}
                                                                    Information
                                                                </h3>

                                                                <hr></hr>
                                                            </div>
                                                            <div className="col-md-2"></div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                Transaction
                                                            </label>
                                                            <div className="col-sm-8 col-form-label">
                                                                {
                                                                    item.transactionId
                                                                }
                                                                <div className="text-danger">
                                                                    <ErrorMessage name="total" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                Client
                                                            </label>
                                                            <div className="col-sm-8 col-form-label">
                                                                {
                                                                    item.clientName
                                                                }
                                                                <div className="text-danger">
                                                                    <ErrorMessage name="total" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                Passport
                                                            </label>
                                                            <div className="col-sm-8 col-form-label">
                                                                {
                                                                    item.passport
                                                                }
                                                                <div className="text-danger">
                                                                    <ErrorMessage name="passport" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                Purpose
                                                            </label>
                                                            <div className="col-sm-8 col-form-label">
                                                                {item.purpose}
                                                                <div className="text-danger">
                                                                    <ErrorMessage name="total" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row mt-2">
                                                            <label className="col-sm-4 col-form-label">
                                                                Status
                                                            </label>
                                                            <div className="col-sm-8 col-form-label">
                                                                {item.status ===
                                                                0
                                                                    ? "Pending"
                                                                    : "Success"}
                                                                <div className="text-danger">
                                                                    <ErrorMessage name="total" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                Total
                                                            </label>
                                                            <div className="col-sm-8">
                                                                <Field
                                                                    type="text"
                                                                    name="total"
                                                                    className="form-control"
                                                                    readOnly
                                                                />
                                                                <div className="text-danger">
                                                                    <ErrorMessage name="total" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                Recieved
                                                            </label>
                                                            <div className="col-sm-8">
                                                                <Field
                                                                    type="text"
                                                                    name="received"
                                                                    className="form-control"
                                                                    readOnly
                                                                />

                                                                <div className="text-danger">
                                                                    <ErrorMessage name="received" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                Due
                                                            </label>
                                                            <div className="col-sm-8">
                                                                <Field
                                                                    type="textarea"
                                                                    name="due"
                                                                    className="form-control"
                                                                    readOnly
                                                                />

                                                                <div className="text-danger">
                                                                    <ErrorMessage name="due" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />

                                                        <div className="form-group row">
                                                            <label className="col-sm-3 col-form-label">
                                                                Amount
                                                            </label>
                                                            <div className="col-sm-6">
                                                                <Field
                                                                    type="number"
                                                                    name="newAmount"
                                                                    className="form-control"
                                                                />

                                                                <div className="text-danger">
                                                                    <ErrorMessage name="newAmount" />
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-3">
                                                                <Button
                                                                    type="submit"
                                                                    className="btn btn-warning form-control"
                                                                >
                                                                    <i className="fas fa-plus"></i>{" "}
                                                                    Add
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            ))}
                    </div>
                </div>
            </section>
        </div>
    );
}
