import React from "react";

export const Footer = () => {
    return (
        <footer class="main-footer">
            <strong>
                Copyright &copy; 2024 <strong>Akaba IT</strong>.
            </strong>
            All rights reserved.
            <div class="float-right d-none d-sm-inline-block">
                <b>Version</b> 1.1.0
            </div>
        </footer>
    );
};
