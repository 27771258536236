import React, { useState, useEffect } from "react";
import Pagination from "./TablePagination";
import TableLimit from "./TableLimit";
import MyTable from "./MyTable";
import _ from "lodash";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import * as formik from "formik";
import { format } from "date-fns";
import * as Yup from "yup";
import { Axios } from "../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FlightListPDF } from "../PDFs/FlightListPDF";

export const FlightsComponent = () => {
    const { Formik } = formik;
    const [sortColumn, setSortColumn] = useState("id");
    const [sortOrder, setSortOrder] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [searchText, setSearchText] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [flights, setFlights] = useState([]);
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);

    const handleSearch = (searchQuery) => {
        setSearchText(searchQuery);
        setCurrentPage(1); // Move this outside of the filter function
    };

    const handleDateChange = (date, setFieldValue, fieldName) => {
        if (fieldName === "dateFromSearch") {
            setSelectedFromDate(date);
        } else if (fieldName === "dateToSearch") {
            setSelectedToDate(date);
        }
        setFieldValue(fieldName, format(date, "dd/MM/yyyy"));
    };

    const schema = Yup.object().shape({
        dateFromSearch: Yup.string().required("Date is required"),
        dateToSearch: Yup.string().required("Date is required"),
    });

    // const agents = useMemo(() => {
    //     // Initialize your agents array here

    // }, []); // Dependency array is empty, meaning it only runs once

    useEffect(() => {
        const getFlights = async () => {
            try {
                const res = await Axios.get("get-flights");
                if (res.status === 200) {
                    setFlights(res.data);
                }
            } catch (err) {
                alert(err);
            }
        };
        getFlights();
        return () => {};
    }, []);

    useEffect(() => {
        const filteredflights = flights.filter(
            (item) =>
                (item.transactionId &&
                    item.transactionId
                        .toString()
                        .toLowerCase()
                        .includes(searchText.toLowerCase())) ||
                (item.agentName &&
                    item.agentName
                        .toString()
                        .toLowerCase()
                        .includes(searchText.toLowerCase())) ||
                (item.clientName &&
                    item.clientName
                        .toString()
                        .toLowerCase()
                        .includes(searchText.toLowerCase()))
        );
        setFilteredData(filteredflights);
    }, [flights, searchText]);

    const columns = [
        { name: "T-ID", path: "transactionId" },
        { name: "Client", path: "clientName" },
        { name: "Passport", path: "passport" },
        { name: "Flight Date", path: "date" },
        { name: "Ticket", path: "ticketNo" },
        { name: "PNR", path: "pnr" },
        { name: "Route", path: "route" },
        { name: "Agent", path: "agentName" },
        { name: "Note", path: "note" },
        { name: "Status", path: "statusp-s" },
        { name: "Action", path: "e" },
    ];

    function onHandleSort(column, order) {
        setSortColumn(column);
        setSortOrder(order);
    }

    function resetAfterLimitChange(limit) {
        setLimit(limit);
        setCurrentPage(1);
    }

    function sortflights() {
        const column = columns.find((column) => column.name === sortColumn);
        if (!column) return filteredData;

        const sortedflights = _.orderBy(filteredData, column.path, sortOrder);
        return sortedflights;
    }

    function paginateflights() {
        const startIndex = (parseInt(currentPage) - 1) * parseInt(limit);
        const endIndex = parseInt(startIndex) + parseInt(limit);
        return sortflights().slice(startIndex, endIndex);
    }

    const currentDate = new Date();

    const formattedDate = currentDate
        .toISOString()
        .replace(/[-:.]/g, "")
        .replace("T", "_")
        .slice(0, -5);

    const fileName = `FlightList_${formattedDate}.pdf`;

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Flights</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li className="breadcrumb-item active">
                                    Flights
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">
                    <div className="card bg-infocard card-info card-outline p-5">
                        <div className="row">
                            <div className="col-md-8 mb-3">
                                <Formik
                                    validationSchema={schema}
                                    initialValues={{
                                        dateFromSearch: "",
                                        dateToSearch: "",
                                    }}
                                    onSubmit={(values, { resetForm }) => {
                                        // Your form submission logic here
                                        // console.log(values):
                                        //resetForm();
                                        const getData = async () => {
                                            try {
                                                const res = await Axios.get(
                                                    `get-searched-data-for-flights`,
                                                    { params: values }
                                                );
                                                if (res.status === 200) {
                                                    setFlights(res.data);
                                                    //console.log(res.data);
                                                }
                                            } catch (error) {
                                                console.error(
                                                    "Error submitting form:",
                                                    error
                                                );
                                            }
                                        };
                                        getData();
                                    }}
                                >
                                    {({ handleSubmit, setFieldValue }) => (
                                        <Form
                                            noValidate
                                            onSubmit={handleSubmit}
                                            className="mb-2 "
                                        >
                                            <div className="row">
                                                <div className="mb-2">
                                                    <DatePicker
                                                        name="dateFromSearch"
                                                        selected={
                                                            selectedFromDate
                                                        }
                                                        onChange={(date) =>
                                                            handleDateChange(
                                                                date,
                                                                setFieldValue,
                                                                "dateFromSearch"
                                                            )
                                                        }
                                                        dateFormat="dd/MM/yyyy"
                                                        className="form-control" // Add the form-control class here
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <DatePicker
                                                        name="dateToSearch"
                                                        selected={
                                                            selectedToDate
                                                        }
                                                        onChange={(date) =>
                                                            handleDateChange(
                                                                date,
                                                                setFieldValue,
                                                                "dateToSearch"
                                                            )
                                                        }
                                                        dateFormat="dd/MM/yyyy"
                                                        className="form-control" // Add the form-control class here
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="mb-2 d-flex align-items-end">
                                                    <button
                                                        className="btn btn-outline-secondary ml-md-2"
                                                        type="submit"
                                                    >
                                                        <i className="nav-icon fas fa-search"></i>{" "}
                                                        Search
                                                    </button>
                                                    <button
                                                        className="btn btn-outline-secondary ml-md-2"
                                                        type="button"
                                                        onClick={async () => {
                                                            setSelectedFromDate(
                                                                null
                                                            );
                                                            setSelectedToDate(
                                                                null
                                                            );
                                                            setFieldValue(
                                                                "dateFromSearch",
                                                                ""
                                                            );
                                                            setFieldValue(
                                                                "dateToSearch",
                                                                ""
                                                            );

                                                            try {
                                                                const res =
                                                                    await Axios.get(
                                                                        "get-flights"
                                                                    );
                                                                if (
                                                                    res.status ===
                                                                    200
                                                                ) {
                                                                    setFlights(
                                                                        res.data
                                                                    );
                                                                }
                                                            } catch (err) {
                                                                alert(err);
                                                            }
                                                        }}
                                                    >
                                                        <i className="nav-icon fas fa-redo-alt"></i>{" "}
                                                        Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className="col-md-2 mb-3">
                                <div
                                    className="input-group"
                                    //style={{ marginLeft: "auto", width: "50%" }}
                                >
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Search"
                                        value={searchText}
                                        onChange={(e) =>
                                            handleSearch(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-md-1 mb-3">
                                <TableLimit
                                    onChange={(limit) =>
                                        resetAfterLimitChange(limit)
                                    }
                                    options={[
                                        { name: "Five", value: 5 },
                                        { name: "Ten", value: 10 },
                                        { name: "Twenty", value: 20 },
                                    ]}
                                />
                            </div>
                            <div className="col-md-1 mb-3">
                                <PDFDownloadLink
                                    document={
                                        <FlightListPDF
                                            Items={filteredData}
                                            tableName={"Flight List"}
                                        />
                                    }
                                    fileName={fileName} // Use the fileName variable here
                                >
                                    {({ loading }) =>
                                        loading ? (
                                            "Loading..."
                                        ) : (
                                            <button className="btn btn-info form-control">
                                                <i className="fas fa-download"></i>
                                                Download
                                            </button>
                                        )
                                    }
                                </PDFDownloadLink>
                            </div>
                        </div>
                        <MyTable
                            columns={columns}
                            items={paginateflights()}
                            sortColumn={sortColumn}
                            sortOrder={sortOrder}
                            onHandleSort={onHandleSort}
                        />
                        <Pagination
                            data={filteredData}
                            //data={transactions}
                            limit={limit}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};
