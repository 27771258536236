import React, { useState, useEffect } from "react";
import { Axios } from "../config";
import { useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Button from "react-bootstrap/Button";
import AlertDismissible from "./Alert";

export function UpdateStatus() {
    const [data, setData] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        const getAgents = async () => {
            try {
                const res = await Axios.get(`transactiondetails/${id}`);
                setData(res.data);
            } catch (err) {
                alert(err);
            }
        };
        getAgents();
    }, [id]);

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Update Status</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li className="breadcrumb-item active">
                                    update-status
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="card card-info card-outline">
                        {data?.map((dat, index) => {
                            return (
                                <div key={index}>
                                    <Formik
                                        initialValues={{
                                            status: data[0]?.status || "",
                                        }}
                                        onSubmit={(values, { resetForm }) => {
                                            const updatedValues = {
                                                ...values,
                                                id: id,
                                            };
                                            const postForm = async () => {
                                                try {
                                                    const res = await Axios.put(
                                                        `/update-status/${id}`,
                                                        updatedValues
                                                    );

                                                    if (res.status === 200) {
                                                        setShowAlert(true);
                                                    }
                                                } catch (error) {
                                                    console.error(
                                                        "Error submitting value:",
                                                        error
                                                    );
                                                }
                                            };
                                            postForm();
                                        }}
                                    >
                                        <Form className="mt-3 form-horizontal col-md-8 mx-auto card no-bg border-secondary p-3">
                                            {showAlert && (
                                                <AlertDismissible
                                                    variant={"success"}
                                                    alertMsg={
                                                        "Record Updated successfully "
                                                    }
                                                />
                                            )}
                                            <div className="row">
                                                <div className="col">
                                                    <div className="card-body">
                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                Agent
                                                            </label>
                                                            <div className="col-sm-8">
                                                                <Field
                                                                    as="text"
                                                                    name="status"
                                                                    className="form-control"
                                                                    readonly
                                                                >
                                                                    {
                                                                        data[0]
                                                                            .agentName
                                                                    }
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                Client
                                                            </label>
                                                            <div className="col-sm-8">
                                                                <Field
                                                                    as="text"
                                                                    name="status"
                                                                    className="form-control"
                                                                    readonly
                                                                >
                                                                    {
                                                                        data[0]
                                                                            .clientName
                                                                    }
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                Purpose
                                                            </label>
                                                            <div className="col-sm-8">
                                                                <Field
                                                                    as="text"
                                                                    name="status"
                                                                    className="form-control"
                                                                    readonly
                                                                >
                                                                    {
                                                                        data[0]
                                                                            .purpose
                                                                    }
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                D/F Date
                                                            </label>
                                                            <div className="col-sm-8">
                                                                <Field
                                                                    as="text"
                                                                    name="status"
                                                                    className="form-control"
                                                                    readonly
                                                                >
                                                                    {
                                                                        data[0]
                                                                            .date
                                                                    }
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                Due
                                                            </label>
                                                            <div className="col-sm-8">
                                                                <Field
                                                                    as="text"
                                                                    name="status"
                                                                    className="form-control"
                                                                    readonly
                                                                >
                                                                    {
                                                                        data[0]
                                                                            .due
                                                                    }
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <h4>Update Status</h4>
                                                    <hr></hr>
                                                    <div className="form-group row">
                                                        <label className="col-sm-4 col-form-label">
                                                            Status
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <Field
                                                                as="select"
                                                                name="status"
                                                                className="form-control"
                                                            >
                                                                <option value="0">
                                                                    Pending
                                                                </option>
                                                                <option value="1">
                                                                    Complete
                                                                </option>
                                                            </Field>
                                                            <div className="text-danger">
                                                                <ErrorMessage name="status" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <Button
                                                                type="submit"
                                                                className="btn btn-warning form-control"
                                                            >
                                                                Update
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </div>
    );
}
