function TableLimit({ onChange, options }) {
    return (
        <select
            onChange={(e) => onChange(e.target.value)}
            // style={{ width: "100px" }}
            aria-label="Default select example"
            className="form-control"
        >
            {options.map((option, index) => {
                return (
                    <option key={index} value={option.value}>
                        {option.name}
                    </option>
                );
            })}
        </select>
    );
}

export default TableLimit;
