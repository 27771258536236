import React, { useState, useEffect } from "react";
import { Axios } from "../config";
import { useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { TransactionDetailsPDF } from "../PDFs/TranDetailsPDF";

export function TransactionDetails() {
    const [data, setData] = useState([]);
    const [due, setDue] = useState([]);
    const { id } = useParams(); // Get the id from the URL

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await Axios.get(`transactiondetails/${id}`);
                if (res.data) {
                    setData(res.data);
                }
            } catch (error) {
                console.error("Error fetching due data:", error);
            }
        };
        fetchData();

        return () => {};
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await Axios.get(`all-due/${id}`);
                if (res.data) {
                    setDue(res.data);
                }
            } catch (error) {
                console.error("Error fetching due data:", error);
            }
        };
        fetchData();

        return () => {};
    }, [id]);
    const currentDate = new Date();

    const formattedDate = currentDate
        .toISOString()
        .replace(/[-:.]/g, "")
        .replace("T", "_")
        .slice(0, -5);

    const fileName = `Transaction_details_${formattedDate}.pdf`;
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Transaction Details</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li className="breadcrumb-item active">
                                    details
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="card bg-infocard card-info card-outline p-5">
                        {data.length > 0 &&
                            data?.map((item, index) => (
                                <div className="mt-3 form-horizontal col-md-8 mx-auto card no-bg border-secondary p-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col">
                                                        <h3>Information</h3>

                                                        <hr></hr>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                Agent
                                                            </label>
                                                            <div className="col-sm-8 col-form-label">
                                                                {item.agentName}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                Client
                                                            </label>
                                                            <div className="col-sm-8 col-form-label">
                                                                {
                                                                    item.clientName
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                Purpose
                                                            </label>
                                                            <div className="col-sm-8 col-form-label">
                                                                {item.purpose}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                D/F date
                                                            </label>
                                                            <div className="col-sm-8 col-form-label">
                                                                {item.date}
                                                            </div>
                                                        </div>

                                                        {/* {item.ticketNo && (
                                                            <>
                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label">
                                                                        Ticket
                                                                    </label>
                                                                    <div className="col-sm-8 col-form-label">
                                                                        {
                                                                            item.ticketNo
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label">
                                                                        Route
                                                                    </label>
                                                                    <div className="col-sm-8 col-form-label">
                                                                        {
                                                                            item.route
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <label className="col-sm-4 col-form-label">
                                                                        PNR
                                                                    </label>
                                                                    <div className="col-sm-8 col-form-label">
                                                                        {
                                                                            item.pnr
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )} */}
                                                        <div className="form-group row">
                                                            <label className="col-sm-4 col-form-label">
                                                                Status
                                                            </label>
                                                            <div className="col-sm-8 col-form-label">
                                                                {item.status ===
                                                                0 ? (
                                                                    <p className="text-danger">
                                                                        Pending
                                                                    </p>
                                                                ) : (
                                                                    <p className="text-success">
                                                                        Complete
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="col">
                                                    <h3>Paymemt History</h3>

                                                    <hr></hr>

                                                    <div className="col">
                                                        <div className="form-group row">
                                                            <label className="col-sm-8 col-form-label">
                                                                Total
                                                            </label>
                                                            <div className="col-sm-4 col-form-label">
                                                                {item.total}
                                                            </div>
                                                        </div>
                                                        <div className="form-group ">
                                                            Recieved
                                                            <hr></hr>
                                                        </div>
                                                        {due.length > 0 &&
                                                            due?.map(
                                                                (
                                                                    dueitem,
                                                                    dueindex
                                                                ) => (
                                                                    <div
                                                                        className="form-group row"
                                                                        key={
                                                                            dueindex
                                                                        }
                                                                    >
                                                                        <p className="col-sm-8">
                                                                            {dueindex +
                                                                                1}
                                                                            {
                                                                                ". "
                                                                            }
                                                                            {
                                                                                dueitem.date
                                                                            }
                                                                        </p>
                                                                        <div className="col-sm-4 col-form-label">
                                                                            {
                                                                                dueitem.dueReceived
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        <hr></hr>
                                                        <div className="form-group row">
                                                            <p className="col-sm-8">
                                                                Total received
                                                            </p>
                                                            <div className="col-sm-4 col-form-label">
                                                                {item.received}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row border border-secondary">
                                                            <label className="col-sm-8 col-form-label">
                                                                Due
                                                            </label>
                                                            <div className="col-sm-4 col-form-label">
                                                                {item.due}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <PDFDownloadLink
                                                                document={
                                                                    <TransactionDetailsPDF
                                                                        Items={
                                                                            data
                                                                        }
                                                                        due={
                                                                            due
                                                                        }
                                                                        tableName={
                                                                            "Transaction List"
                                                                        }
                                                                    />
                                                                }
                                                                fileName={
                                                                    fileName
                                                                } // Use the fileName variable here
                                                            >
                                                                {({
                                                                    loading,
                                                                }) =>
                                                                    loading ? (
                                                                        "Loading..."
                                                                    ) : (
                                                                        <button className="btn btn-info form-control">
                                                                            <i className="fas fa-download"></i>
                                                                            Download
                                                                        </button>
                                                                    )
                                                                }
                                                            </PDFDownloadLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </section>
        </div>
    );
}
