import React from "react";
import { Header } from "./Header";
import { Menu } from "./Menu";
import { Footer } from "./Footer";
import { UpdateStatus } from "../Components/UpdateStatus";

export const UpdateStat = () => {
    return (
        <div className="wrapper">
            <Header />
            <Menu />
            <UpdateStatus />
            <Footer />
        </div>
    );
};
