//import { useState } from "react";
//import Alert from "react-bootstrap/Alert";

function AlertDismissible({ variant, alertMsg }) {
    //const [show, setShow] = useState(true);

    if (true) {
        return (
            // <Alert variant={variant} onClose={() => setShow(false)} dismissible>
            //     {/* <Alert.Heading>Oh snap! You got an error!</Alert.Heading> */}
            //     {alertMsg}
            // </Alert>
            <div className={`alert alert-dismissible`}>
                <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-hidden="true"
                >
                    &times;
                </button>
                <h5 className={`text-${variant} text-center`}>
                    <i className="icon fas fa-check"></i>{alertMsg ? alertMsg : "Data Added Successfully"}
                </h5>
                {/* Data added successfully. */}
            </div>
        );
    }
}

export default AlertDismissible;
