import React from "react";
import { Header } from "./Header";
import { Menu } from "./Menu";
import { Footer } from "./Footer";
import { DueComponent } from "../Components/DueComponent";

export const AddDue = () => {
    return (
        <div className="wrapper">
            <Header />
            <Menu />
            <DueComponent />
            <Footer />
        </div>
    );
};
