import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Axios } from "../config";

export const DashComponent = () => {
    const [dashdata, setDashdata] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await Axios.get("/total-dash-data");
                if (res.status === 200) {
                    setDashdata(res.data);
                }
            } catch (err) {
                alert(err);
            }
        };

        getData();
        return () => {};
    }, []);

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Dashboard</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li className="breadcrumb-item active">
                                    Dashboard
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">
                    {dashdata?.map((item, index) => {
                        return (
                            <div className="row" key={index}>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="small-box ">
                                                <div className="inner">
                                                    <h3>0</h3>

                                                    <p>Something</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="ion ion-stats-bars"></i>
                                                </div>
                                                <Link
                                                    to="/"
                                                    className="small-box-footer"
                                                >
                                                    More info{" "}
                                                    <i className="fas fa-arrow-circle-right"></i>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="small-box ">
                                                <div className="inner">
                                                    <h3>
                                                        {item.total_transaction_amount
                                                            ? item.total_transaction_amount
                                                            : 0}
                                                    </h3>

                                                    <p>Total Transactions</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="ion ion-stats-bars"></i>
                                                </div>
                                                <Link
                                                    to="/total-transactions"
                                                    className="small-box-footer"
                                                >
                                                    More info{" "}
                                                    <i className="fas fa-arrow-circle-right"></i>
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="small-box">
                                                <div className="inner">
                                                    <h3>
                                                        {" "}
                                                        {item.total_due
                                                            ? item.total_due
                                                            : 0}
                                                    </h3>

                                                    <p>Total Due</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="ion ion-pie-graph"></i>
                                                </div>
                                                <Link
                                                    to="/due-list"
                                                    className="small-box-footer"
                                                >
                                                    More info{" "}
                                                    <i className="fas fa-arrow-circle-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div class="card">
                                        <div className="card-body">
                                            <Link to="/flights">
                                                <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                                                    <p className="text-success text-xl">
                                                        <i className="ion ion-android-plane"></i>{" "}
                                                        {
                                                            item.total_ticket_purpose
                                                        }{" "}
                                                        Flights
                                                    </p>
                                                    <div className="d-flex flex-column text-right">
                                                        <span className="text-warning">
                                                            Pending :{" "}
                                                            {
                                                                item.total_ticket_pending
                                                            }
                                                        </span>
                                                        <span className="text-success">
                                                            Completed :{" "}
                                                            {item.total_ticket_purpose -
                                                                item.total_ticket_pending}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="/bmet-list">
                                                <div className="d-flex justify-content-between align-items-center border-bottom mb-3">
                                                    <p className="text-info text-xl">
                                                        <i className="ion ion-ios-box"></i>{" "}
                                                        {
                                                            item.total_bmet_purpose
                                                        }{" "}
                                                        BMET
                                                    </p>
                                                    <div className="d-flex flex-column text-right">
                                                        <span className="text-warning">
                                                            Pending :{" "}
                                                            {
                                                                item.total_bmet_pending
                                                            }
                                                        </span>
                                                        <span className="text-success">
                                                            Completed :{" "}
                                                            {item.total_bmet_purpose -
                                                                item.total_bmet_pending}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="/agents">
                                                <div className="d-flex justify-content-between align-items-center mb-0">
                                                    <p className="text-secondary text-xl">
                                                        <i className="ion ion-ios-people-outline"></i>{" "}
                                                        {item.total_agents}{" "}
                                                        Agents
                                                    </p>
                                                    <div className="d-flex flex-column text-right">
                                                        <span className="text-danger">
                                                            Blocked :{" "}
                                                            {
                                                                item.total_agents_blocked
                                                            }
                                                        </span>
                                                        <span className="text-success">
                                                            Active :{" "}
                                                            {item.total_agents -
                                                                item.total_agents_blocked}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>
        </div>
    );
};
