import React, { useState } from "react";

import { TicketComponent } from "./TicketComponent";
import { BMETcardComponent } from "./BMETcardComponent";
import { OtherComponent } from "./OtherComponent";

export function NewTransactionForm() {
    // State to track the selected option
    const [selectedOption, setSelectedOption] = useState("");

    // Function to handle change in select element
    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
    };

    // Render component based on selected option
    const renderComponent = () => {
        switch (selectedOption) {
            case "Ticket":
                return <TicketComponent />;
            case "BMET":
                return <BMETcardComponent />;
            case "Other":
                return <OtherComponent />;
            default:
                return null; // Render nothing if no option selected
        }
    };

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Add Transaction</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li className="breadcrumb-item active">
                                    Add-Transaction
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="card card-info card-outline">
                        <div className="mt-3 form-horizontal col-md-8 mx-auto card no-bg border-secondary p-3">
                            <div className="card-body">
                                <div className="form-group row">
                                    <label htmlFor="purpose">
                                        Select Purpose:
                                    </label>
                                    <select
                                        id="purpose"
                                        name="purpose"
                                        className="form-control"
                                        onChange={handleSelectChange}
                                        value={selectedOption}
                                    >
                                        <option value="" disabled>
                                            Select Purpose
                                        </option>
                                        <option value="Ticket">Ticket</option>
                                        <option value="BMET">BMET</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                {renderComponent()}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
