import React, { useState, useEffect } from "react";
import Pagination from "./TablePagination";
import TableLimit from "./TableLimit";
import MyTable from "./MyTable";
import _ from "lodash";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import * as formik from "formik";
import { format } from "date-fns";
import * as Yup from "yup";
import { Axios } from "../config";
import { useParams } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { AgentDetailsPDF } from "../PDFs/AgentDetailsPDF";
import { AgentTransactionPDF } from "../PDFs/AgentTransactionPDF";

export const AgentDetailsComponent = () => {
    const { Formik } = formik;
    const [sortColumn, setSortColumn] = useState("id");
    const [sortOrder, setSortOrder] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [transactions, setTransactions] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [agentDetails, setAgentDetails] = useState([]);
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);

    const handleSearch = (searchQuery) => {
        setSearchText(searchQuery);
        setCurrentPage(1); // Move this outside of the filter function
    };
    const handleDateChange = (date, setFieldValue, fieldName) => {
        if (fieldName === "dateFromSearch") {
            setSelectedFromDate(date);
        } else if (fieldName === "dateToSearch") {
            setSelectedToDate(date);
        }
        setFieldValue(fieldName, format(date, "dd/MM/yyyy"));
    };

    const schema = Yup.object().shape({
        dateFromSearch: Yup.string().required("Date is required"),
        dateToSearch: Yup.string().required("Date is required"),
    });
    const { id } = useParams(); // Get the id from the URL

    useEffect(() => {
        const agentDetails = async () => {
            try {
                const res = await Axios.get(`agent/${id}`);
                if (res.status === 200) {
                    setAgentDetails(res.data);
                }
            } catch (err) {
                alert(err);
            }
        };
        agentDetails();
        return () => {};
    }, [id]);

    useEffect(() => {
        const agentTrans = async () => {
            try {
                const res = await Axios.get(`agent-transactions/${id}`);
                if (res.status === 200) {
                    setTransactions(res.data);
                }
            } catch (err) {
                alert(err);
            }
        };
        agentTrans();
        return () => {};
    }, [id]);

    useEffect(() => {
        const filteredAgents = transactions.filter(
            (item) =>
                (item.clientName &&
                    item.clientName
                        .toString()
                        .toLowerCase()
                        .includes(searchText.toLowerCase())) ||
                (item.transactionId &&
                    item.transactionId
                        .toString()
                        .toLowerCase()
                        .includes(searchText.toLowerCase()))
        );
        setFilteredData(filteredAgents);
    }, [transactions, searchText]);

    const columns = [
        { name: "T-ID", path: "transactionId" },
        { name: "Client", path: "clientName" },
        { name: "Passport", path: "passport" },
        { name: "Purpose", path: "purpose" },
        { name: "D/F Date", path: "date" },
        { name: "TicketNo", path: "ticketNo" },
        { name: "Route", path: "route" },
        { name: "PNR", path: "pnr" },
        { name: "Total", path: "total" },
        { name: "Received", path: "received" },
        { name: "Due", path: "due" },
        { name: "Note", path: "note" },
        { name: "Action", path: "t-d-e-d" },
    ];

    function onHandleSort(column, order) {
        setSortColumn(column);
        setSortOrder(order);
    }

    function resetAfterLimitChange(limit) {
        setLimit(limit);
        setCurrentPage(1);
    }

    function sortTransactions() {
        const column = columns.find((column) => column.name === sortColumn);
        if (!column) return filteredData;

        const sortedTransactions = _.orderBy(
            filteredData,
            column.path,
            sortOrder
        );
        return sortedTransactions;
    }

    function paginateTransactions() {
        const startIndex = (parseInt(currentPage) - 1) * parseInt(limit);
        const endIndex = parseInt(startIndex) + parseInt(limit);
        return sortTransactions().slice(startIndex, endIndex);
    }

    const handleDeleteClick = async (id) => {
        // Show an alert to confirm deletion
        const confirmed = window.confirm(
            "Are you sure you want to delete this transaction?"
        );

        if (confirmed) {
            try {
                // Delete the agent
                await Axios.delete(`transaction-delete/${id}`);

                // Fetch the updated list of agents
                const updatedAgentsRes = await Axios.get(
                    `agent-transactions/${id}`
                );
                if (updatedAgentsRes.status === 200) {
                    // Update the agents state with the new data
                    setTransactions(updatedAgentsRes.data);
                }
            } catch (err) {
                // Handle errors here
                console.error("Error deleting transaction:", err);
                alert("Error deleting transaction");
            }
        } else {
            // User canceled the deletion, do nothing or show a message
            console.log("Deletion canceled by user");
        }
    };

    const currentDate = new Date();

    const formattedDate = currentDate
        .toISOString()
        .replace(/[-:.]/g, "")
        .replace("T", "_")
        .slice(0, -5);

    const fileName = `AgentTransactions_${formattedDate}.pdf`;

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Agent Profile</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li className="breadcrumb-item active">
                                    Agent-Profile
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="card card-info card-outline">
                        {agentDetails.map((item, index) => {
                            return (
                                <div
                                    className="card-body box-profile"
                                    key={index}
                                >
                                    <div className="text-center">
                                        <h3 className="text-center">
                                            {item.agentName}
                                        </h3>
                                        <p className="text-muted">
                                            {item.agentID}
                                        </p>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-4">
                                            <strong>
                                                <i className="fas fa-phone mr-1"></i>{" "}
                                                Contact
                                            </strong>

                                            <p className="text-muted">
                                                {item.phoneNo}
                                            </p>

                                            <strong>
                                                <i className="fas fa-map-marker-alt mr-1"></i>{" "}
                                                Address
                                            </strong>

                                            <p className="text-muted">
                                                {item.address}
                                            </p>
                                        </div>
                                        <div className="col-md-4">
                                            <strong>Total Amount</strong>

                                            <p className="text-muted">
                                                <i className="fas fa-money-bill mr-1"></i>{" "}
                                                {item.total}
                                            </p>

                                            <strong>Recieved Amount</strong>

                                            <p className="text-muted">
                                                <i className="fas fa-money-bill mr-1"></i>{" "}
                                                {item.received}
                                            </p>

                                            <strong>Due Amount</strong>

                                            <p className="text-muted">
                                                <i className="fas fa-money-bill mr-1"></i>{" "}
                                                {item.due}
                                            </p>
                                        </div>
                                        <div className="col-md-4">
                                            <strong>
                                                <i className="fas fa-pencil-alt mr-1"></i>{" "}
                                                Last Transaction
                                            </strong>

                                            <p className="text-muted">
                                                Date: {item.lastTransactionDate}
                                            </p>

                                            <strong>
                                                <i className="fas fa-circle mr-1"></i>{" "}
                                                Status :{" "}
                                                {item.status === 1 ? (
                                                    <span className="p-1 text-danger rounded">
                                                        Blocked
                                                    </span>
                                                ) : (
                                                    <span className="p-1 text-success rounded">
                                                        Active
                                                    </span>
                                                )}
                                            </strong>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-10"></div>
                                        <div className="col-md-2">
                                            <PDFDownloadLink
                                                document={
                                                    <AgentDetailsPDF
                                                        details={agentDetails}
                                                        Items={filteredData}
                                                        tableName={
                                                            "Agent Details"
                                                        }
                                                    />
                                                }
                                                fileName={fileName} // Use the fileName variable here
                                            >
                                                {({ loading }) =>
                                                    loading ? (
                                                        "Loading..."
                                                    ) : (
                                                        <button className="btn btn-info form-control">
                                                            <i className="fas fa-download"></i>
                                                            Download
                                                        </button>
                                                    )
                                                }
                                            </PDFDownloadLink>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div className="card bg-infocard card-info card-outline p-5">
                        {/* <h3 className="text-center">Transactions</h3>
                                <hr /> */}
                        <div className="row">
                            <div className="col-md-8 mb-3">
                                <Formik
                                    validationSchema={schema}
                                    initialValues={{
                                        dateFromSearch: "",
                                        dateToSearch: "",
                                    }}
                                    onSubmit={(values, { resetForm }) => {
                                        // Your form submission logic here

                                        //resetForm();
                                        const getData = async () => {
                                            try {
                                                const res = await Axios.get(
                                                    `get-searched-data-for-agent-profile/${id}`,
                                                    { params: values }
                                                );
                                                if (res.status === 200) {
                                                    setTransactions(res.data);
                                                    //console.log(res.data);
                                                }
                                            } catch (error) {
                                                console.error(
                                                    "Error submitting form:",
                                                    error
                                                );
                                            }
                                        };
                                        getData();
                                        //console.log(values);
                                    }}
                                >
                                    {({ handleSubmit, setFieldValue }) => (
                                        <Form
                                            noValidate
                                            onSubmit={handleSubmit}
                                            className="mb-2 "
                                        >
                                            <div className="row">
                                                <div className="mb-2">
                                                    <DatePicker
                                                        name="dateFromSearch"
                                                        selected={
                                                            selectedFromDate
                                                        }
                                                        onChange={(date) =>
                                                            handleDateChange(
                                                                date,
                                                                setFieldValue,
                                                                "dateFromSearch"
                                                            )
                                                        }
                                                        dateFormat="dd/MM/yyyy"
                                                        className="form-control" // Add the form-control class here
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <DatePicker
                                                        name="dateToSearch"
                                                        selected={
                                                            selectedToDate
                                                        }
                                                        onChange={(date) =>
                                                            handleDateChange(
                                                                date,
                                                                setFieldValue,
                                                                "dateToSearch"
                                                            )
                                                        }
                                                        dateFormat="dd/MM/yyyy"
                                                        className="form-control" // Add the form-control class here
                                                        autoComplete="off"
                                                    />
                                                </div>
                                                <div className="mb-2 d-flex align-items-end">
                                                    <button
                                                        className="btn btn-outline-secondary ml-md-2"
                                                        type="submit"
                                                    >
                                                        <i className="nav-icon fas fa-search"></i>{" "}
                                                        Search
                                                    </button>
                                                    <button
                                                        className="btn btn-outline-secondary ml-md-2"
                                                        type="button"
                                                        onClick={async () => {
                                                            setSelectedFromDate(
                                                                null
                                                            );
                                                            setSelectedToDate(
                                                                null
                                                            );
                                                            setFieldValue(
                                                                "dateFromSearch",
                                                                ""
                                                            );
                                                            setFieldValue(
                                                                "dateToSearch",
                                                                ""
                                                            );

                                                            try {
                                                                const res =
                                                                    await Axios.get(
                                                                        `agent-transactions/${id}`
                                                                    );
                                                                if (
                                                                    res.status ===
                                                                    200
                                                                ) {
                                                                    setTransactions(
                                                                        res.data
                                                                    );
                                                                }
                                                            } catch (err) {
                                                                alert(err);
                                                            }
                                                        }}
                                                    >
                                                        <i className="nav-icon fas fa-redo-alt"></i>{" "}
                                                        Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className="col-md-2 mb-3">
                                <div
                                    className="input-group"
                                    //style={{ marginLeft: "auto", width: "50%" }}
                                >
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Search"
                                        value={searchText}
                                        onChange={(e) =>
                                            handleSearch(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-md-1 mb-3">
                                <TableLimit
                                    onChange={(limit) =>
                                        resetAfterLimitChange(limit)
                                    }
                                    options={[
                                        { name: "Five", value: 5 },
                                        { name: "Ten", value: 10 },
                                        { name: "Twenty", value: 20 },
                                    ]}
                                />
                            </div>
                            <div className="col-md-1 mb-3">
                                <PDFDownloadLink
                                    document={
                                        <AgentTransactionPDF
                                            details={agentDetails}
                                            Items={filteredData}
                                            tableName={"Agent Transactions"}
                                        />
                                    }
                                    fileName={fileName} // Use the fileName variable here
                                >
                                    {({ loading }) =>
                                        loading ? (
                                            "Loading..."
                                        ) : (
                                            <button className="btn btn-info form-control">
                                                <i className="fas fa-download"></i>
                                                Download
                                            </button>
                                        )
                                    }
                                </PDFDownloadLink>
                            </div>
                        </div>
                        <MyTable
                            columns={columns}
                            items={paginateTransactions()}
                            sortColumn={sortColumn}
                            sortOrder={sortOrder}
                            onHandleSort={onHandleSort}
                            onDelete={handleDeleteClick}
                            //onDownload={handleDownloadClick}
                        />
                        <Pagination
                            data={filteredData}
                            //data={transactions}
                            limit={limit}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};
