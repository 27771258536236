import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Dashboard } from "./Pages/Dashboard";
import { AddTransaction } from "./Pages/AddTransaction";
import { AgentProfile } from "./Pages/AgentProfile";
import { Agents } from "./Pages/Agents";
import { Flights } from "./Pages/Flights";
import { TodaysTransactions } from "./Pages/TodaysTransactions";
import { TotalTransactions } from "./Pages/Totaltransactions";
import { AddAgent } from "./Pages/AddAgent";
import { BMETList } from "./Pages/BMETList";
import { EditAgent } from "./Pages/EditAgent";
import { Duelist } from "./Pages/Duelist";
import { AddDue } from "./Pages/AddDue";
import { TransactionDetail } from "./Pages/TransactionDetail";
import { UpdateStat } from "./Pages/Update";
import { LoginPage } from "./Pages/LoginPage";
import { OtherList } from "./Pages/OtherList";

function App() {
    const navigate = useNavigate();

    useEffect(() => {
        // Check login status from local storage
        const isLoggedIn = localStorage.getItem("isLoggedIn");

        // If user is not logged in, redirect to the login page
        if (!isLoggedIn) {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/transaction-form" element={<AddTransaction />} />
                <Route path="/agents" element={<Agents />} />
                <Route path="/add-agent" element={<AddAgent />} />
                <Route path="/edit-agent/:id" element={<EditAgent />} />
                <Route path="/flights" element={<Flights />} />
                <Route path="/bmet-list" element={<BMETList />} />
                <Route path="/agent/:id" element={<AgentProfile />} />
                <Route path="/due-list" element={<Duelist />} />
                <Route path="/due-form/:id" element={<AddDue />} />
                <Route path="/update/:id" element={<UpdateStat />} />
                <Route path="/others" element={<OtherList />} />
                <Route
                    path="/view-transaction/:id"
                    element={<TransactionDetail />}
                />
                <Route
                    path="/todays-transactions"
                    element={<TodaysTransactions />}
                />
                <Route
                    path="/total-transactions"
                    element={<TotalTransactions />}
                />
            </Routes>
        </>
    );
}

export default App;
