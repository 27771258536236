import React, { useState, useEffect } from "react";
import { Axios } from "../config";
import { Link, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import AlertDismissible from "./Alert";

export function EditAgentComponent() {
    const [data, setData] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        const getAgents = async () => {
            try {
                const res = await Axios.get(`agent/${id}`);
                setData(res.data);
            } catch (err) {
                alert(err);
            }
        };
        getAgents();
    }, [id]);

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Edit Agent</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li className="breadcrumb-item active">
                                    Edit-Agent
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="card card-info card-outline">
                        {data?.map((dat, index) => {
                            return (
                                <div key={index}>
                                    <Formik
                                        initialValues={{
                                            agentName: data[0]?.agentName || "",
                                            phoneNumber: data[0]?.phoneNo || "",
                                            address: data[0]?.address || "",
                                            status: data[0]?.status || "",
                                        }}
                                        validationSchema={Yup.object().shape({
                                            agentName:
                                                Yup.string().required(
                                                    "Name is required"
                                                ),
                                            phoneNumber: Yup.string()
                                                .matches(
                                                    /^[0-9]{11}$/,
                                                    "Phone number must be 11 digits"
                                                )
                                                .required(
                                                    "Phone number is required"
                                                ),
                                            address: Yup.string().required(
                                                "Address is required"
                                            ),
                                        })}
                                        onSubmit={(values, { resetForm }) => {
                                            const updatedValues = {
                                                ...values,
                                                agentId: id,
                                            };
                                            const postForm = async () => {
                                                try {
                                                    const res = await Axios.put(
                                                        `/update-agent`,
                                                        updatedValues
                                                    );

                                                    if (res.status === 200) {
                                                        setShowAlert(true);
                                                    }
                                                } catch (error) {
                                                    console.error(
                                                        "Error submitting value:",
                                                        error
                                                    );
                                                }
                                            };
                                            postForm();
                                        }}
                                    >
                                        <Form className="mt-3 form-horizontal col-md-8 mx-auto card no-bg border-secondary p-3">
                                            <div className="card-body">
                                                {showAlert && (
                                                    <AlertDismissible
                                                        variant={"success"}
                                                        alertMsg={
                                                            "Record Updated successfully "
                                                        }
                                                    />
                                                )}
                                                <div className="form-group row">
                                                    <label className="col-sm-2 col-form-label">
                                                        Name
                                                    </label>
                                                    <div className="col-sm-10">
                                                        <Field
                                                            type="text"
                                                            name="agentName"
                                                            className="form-control"
                                                        />
                                                        <div className="text-danger">
                                                            <ErrorMessage name="agentName" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-2 col-form-label">
                                                        Phone
                                                    </label>
                                                    <div className="col-sm-10">
                                                        <Field
                                                            type="text"
                                                            name="phoneNumber"
                                                            className="form-control"
                                                        />

                                                        <div className="text-danger">
                                                            <ErrorMessage name="phoneNumber" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-2 col-form-label">
                                                        Address
                                                    </label>
                                                    <div className="col-sm-10">
                                                        <Field
                                                            type="textarea"
                                                            name="address"
                                                            className="form-control"
                                                        />

                                                        <div className="text-danger">
                                                            <ErrorMessage name="address" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-2 col-form-label">
                                                        Status
                                                    </label>
                                                    <div className="col-sm-2">
                                                        <Field
                                                            as="select"
                                                            name="status"
                                                            className="form-control"
                                                        >
                                                            <option value="0">
                                                                Active
                                                            </option>
                                                            <option value="1">
                                                                Blocked
                                                            </option>
                                                        </Field>
                                                        <div className="text-danger">
                                                            <ErrorMessage name="status" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-2">
                                                    <Link to="/agents">
                                                        <Button className="btn btn-info form-control">
                                                            <i className="fas fa-arrow-left"></i>{" "}
                                                            Back
                                                        </Button>
                                                    </Link>
                                                </div>
                                                <div className="col">
                                                    <Button
                                                        type="submit"
                                                        className="btn btn-warning form-control"
                                                    >
                                                        Update
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </div>
    );
}
