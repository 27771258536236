import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Axios } from "../config";
import AlertDismissible from "../Components/Alert";

export const LoginPage = () => {
    const [showAlert, setShowAlert] = useState(false);

    return (
        <div className="container">
            <div className="justify-content-center d-flex mt-5">
                <div className="card card-outline col-md-4 card-primary mt-5">
                    <div className="card-header text-center">
                        <p className="h1">
                            <b>SEPARA</b> Soft
                        </p>
                    </div>
                    <div className="card-body">
                        <Formik
                            initialValues={{ email: "", password: "" }}
                            validationSchema={Yup.object({
                                email: Yup.string()
                                    .email("Invalid email address")
                                    .required("Required"),
                                password: Yup.string().required("Required"),
                            })}
                            onSubmit={async (values, { setSubmitting }) => {
                                try {
                                    const res = await Axios.post(`userlogin`, {
                                        email: values.email,
                                        password: values.password,
                                    });

                                    if (res.status === 200) {
                                        localStorage.setItem(
                                            "isLoggedIn",
                                            true
                                        );
                                        localStorage.setItem(
                                            "userEmail",
                                            values.email
                                        );
                                        window.location.href = "/"; // Redirect to home URL
                                    }
                                } catch (err) {
                                    setShowAlert(true); // Show alert for incorrect credentials
                                }
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <div className="input-group mb-3">
                                        <label className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-envelope"></i>
                                            </span>
                                        </label>
                                        <Field
                                            type="email"
                                            name="email"
                                            className="form-control"
                                            placeholder="Email Address"
                                        />
                                        <ErrorMessage
                                            name="email"
                                            component="div"
                                            className="text-danger"
                                        />
                                    </div>
                                    <div className="input-group mb-3">
                                        <label className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fas fa-lock"></i>
                                            </span>
                                        </label>
                                        <Field
                                            type="password"
                                            name="password"
                                            className="form-control"
                                            placeholder="Password"
                                        />
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                            className="text-danger"
                                        />
                                    </div>
                                    {showAlert && (
                                        <AlertDismissible
                                            variant={"danger"}
                                            alertMsg={"Credentials not matched"}
                                        />
                                    )}
                                    <div className=" justify-content-center d-flex">
                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="btn btn-primary"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};
