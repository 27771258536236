import React from "react";
import { Link } from "react-router-dom";

export const Menu = () => {
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <div className="brand-link">
                <img
                    src="https://separaoverseas.com/wp-content/uploads/2023/03/WhatsApp-Image-2024-01-29-at-4.01.58-PM.jpeg"
                    alt="AdminLTE Logo"
                    className="brand-image img-circle elevation-3"
                    // style="opacity: .8"
                />
                <span className="brand-text font-weight-light">
                    SEPARA - Soft
                </span>
            </div>

            <div className="sidebar">
                <nav className="mt-2">
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                    >
                        <hr></hr>
                        <li className="nav-item">
                            <Link to="/" className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>Dashboard</p>
                            </Link>
                        </li>
                        <hr></hr>

                        <li className="nav-item">
                            <Link to="/flights" className="nav-link">
                                <i className="nav-icon fas fa-plane"></i>
                                <p>Flights</p>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/bmet-list" className="nav-link">
                                <i className="nav-icon fas fa-user"></i>
                                <p>BMET</p>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/others" className="nav-link">
                                <i className="nav-icon fas fa-tree"></i>
                                <p>Others</p>
                            </Link>
                        </li>

                        <li class="nav-header mt-2">TRANSACTIONS</li>

                        <li className="nav-item">
                            <Link to="/transaction-form" className="nav-link">
                                <i className="fas fa-plus nav-icon"></i>
                                <p>Add Transaction</p>
                            </Link>
                        </li>

                        {/* <li className="nav-item">
                            <Link
                                to="/todays-transactions"
                                className="nav-link"
                            >
                                <i className="far fa-circle nav-icon"></i>
                                <p>Todays Transaction</p>
                            </Link>
                        </li> */}
                        <li className="nav-item">
                            <Link to="/total-transactions" className="nav-link">
                                <i className="far fa-circle nav-icon"></i>
                                <p>Transactions</p>
                            </Link>
                        </li>

                        <li class="nav-header mt-2">DUE</li>

                        <li className="nav-item">
                            <Link to="/due-list" className="nav-link">
                                <i className="far fa-circle nav-icon"></i>
                                <p>Due List</p>
                            </Link>
                        </li>

                        <li class="nav-header mt-2">AGENTS</li>

                        <li className="nav-item">
                            <Link to="/add-agent" className="nav-link">
                                <i className="fas fa-plus nav-icon"></i>
                                <p>Add Agent</p>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/agents" className="nav-link">
                                <i className="far fa-circle nav-icon"></i>
                                <p>Agents</p>
                            </Link>
                        </li>

                        {/* <li class="nav-header mt-2">USER</li>

                        <li className="nav-item">
                            <Link to="/add-user" className="nav-link">
                                <i className="far fa-circle nav-icon"></i>
                                <p>Add User</p>
                            </Link>
                        </li> */}
                    </ul>
                </nav>
            </div>
        </aside>
    );
};
