import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

function MyTable({
    columns,
    items,
    sortColumn,
    sortOrder,
    onHandleSort,
    onDelete,
    onDownload,
}) {
    return (
        <table className="table">
            <TableHeader
                columns={columns}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                onHandleSort={onHandleSort}
            />
            <TableBody
                columns={columns}
                items={items}
                onDelete={onDelete}
                onDownload={onDownload}
            />
        </table>
    );
}

export default MyTable;
