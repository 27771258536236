import React, { useState, useEffect } from "react";
import Pagination from "./TablePagination";
import TableLimit from "./TableLimit";
import MyTable from "./MyTable";
import _ from "lodash";
import { Axios } from "../config";

export const TodaystranComponent = () => {
    const [transactions, setTransactions] = useState([]);
    const [sortColumn, setSortColumn] = useState("id");
    const [sortOrder, setSortOrder] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [searchText, setSearchText] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    const handleSearch = (searchQuery) => {
        setSearchText(searchQuery);
        setCurrentPage(1); // Move this outside of the filter function
    };

    useEffect(() => {
        const Trans = async () => {
            try {
                const res = await Axios.get(`todays-transaction`);
                if (res.status === 200) {
                    setTransactions(res.data);
                }
            } catch (err) {
                alert(err);
            }
        };
        Trans();
        return () => {};
    }, []);

    useEffect(() => {
        const filteredTransactions = transactions.filter(
            (item) =>
                (item.transactionId &&
                    item.transactionId
                        .toString()
                        .toLowerCase()
                        .includes(searchText.toLowerCase())) ||
                (item.agentName &&
                    item.agentName
                        .toString()
                        .toLowerCase()
                        .includes(searchText.toLowerCase())) ||
                (item.clientName &&
                    item.clientName
                        .toString()
                        .toLowerCase()
                        .includes(searchText.toLowerCase())) ||
                (item.date &&
                    item.date
                        .toString()
                        .toLowerCase()
                        .includes(searchText.toLowerCase()))
        );
        setFilteredData(filteredTransactions);
    }, [transactions, searchText]);

    const columns = [
        { name: "T-ID", path: "transactionId" },
        { name: "Agent", path: "agentName" },
        { name: "Client", path: "clientName" },
        { name: "Purpose", path: "purpose" },
        { name: "D/F. Date", path: "date" },
        { name: "Total", path: "total" },
        { name: "Received", path: "received" },
        { name: "Due", path: "due" },
        { name: "Note", path: "note" },
    ];

    function onHandleSort(column, order) {
        setSortColumn(column);
        setSortOrder(order);
    }

    function resetAfterLimitChange(limit) {
        setLimit(limit);
        setCurrentPage(1);
    }

    function sortTransactions() {
        const column = columns.find((column) => column.name === sortColumn);
        if (!column) return filteredData;

        const sortedTransactions = _.orderBy(
            filteredData,
            column.path,
            sortOrder
        );
        return sortedTransactions;
    }

    function paginateTransactions() {
        const startIndex = (parseInt(currentPage) - 1) * parseInt(limit);
        const endIndex = parseInt(startIndex) + parseInt(limit);
        return sortTransactions().slice(startIndex, endIndex);
    }
    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Todays Transaction</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li className="breadcrumb-item active">
                                    Todays Transaction
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">
                    <div className="card bg-infocard card-info card-outline p-5">
                        <div className="row">
                            <div className="col-md-8 mb-3"></div>

                            <div className="col-md-2 mb-3">
                                <div
                                    className="input-group"
                                    //style={{ marginLeft: "auto", width: "50%" }}
                                >
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Search"
                                        value={searchText}
                                        onChange={(e) =>
                                            handleSearch(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-md-1 mb-3">
                                <TableLimit
                                    onChange={(limit) =>
                                        resetAfterLimitChange(limit)
                                    }
                                    options={[
                                        { name: "Five", value: 5 },
                                        { name: "Ten", value: 10 },
                                        { name: "Twenty", value: 20 },
                                    ]}
                                />
                            </div>
                            <div className="col-md-1 mb-3">
                                <button className="btn btn-info form-control">
                                    <i className="fas fa-download"></i> Download
                                </button>
                            </div>
                        </div>
                        <MyTable
                            columns={columns}
                            items={paginateTransactions()}
                            sortColumn={sortColumn}
                            sortOrder={sortOrder}
                            onHandleSort={onHandleSort}
                        />
                        <Pagination
                            data={filteredData}
                            //data={transactions}
                            limit={limit}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};
