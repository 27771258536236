import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import { Axios } from "../config";
import AlertDismissible from "./Alert";
import { Link } from "react-router-dom";

export function NewAgentComponent() {
    const [showAlert, setShowAlert] = useState(false);

    const generateRandomString = (length) => {
        let result = "";

        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const numbers = "0123456789";
        const charactersLength = characters.length;
        const numbersLength = numbers.length;
        for (let i = 0; i < length; i++) {
            if (i < 2) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                );
            } else {
                result += numbers.charAt(
                    Math.floor(Math.random() * numbersLength)
                );
            }
        }
        return result;
    };

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Add Agent</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="/">Home</a>
                                </li>
                                <li className="breadcrumb-item active">
                                    Add-Agent
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="card card-info card-outline">
                        <Formik
                            initialValues={{
                                agentName: "",
                                phoneNumber: "",
                                address: "",
                            }}
                            validationSchema={Yup.object().shape({
                                agentName:
                                    Yup.string().required("Name is required"),
                                phoneNumber: Yup.string()
                                    // .matches(
                                    //     /^[0-9]{11}$/,
                                    //     "Phone number must be 11 digits"
                                    // )
                                    .required("Phone number is required"),
                                address: Yup.string().required(
                                    "Address is required"
                                ),
                            })}
                            onSubmit={(values, { resetForm }) => {
                                const agentId = generateRandomString(6);
                                const updatedValues = {
                                    ...values,
                                    agentId: agentId,
                                };

                                const postForm = async () => {
                                    try {
                                        const res = await Axios.post(
                                            `add-agent`,
                                            updatedValues
                                        );
                                        if (res.status === 200) {
                                            setShowAlert(true);
                                            resetForm();
                                        }
                                    } catch (error) {
                                        if (
                                            error.response &&
                                            error.response.status === 400
                                        ) {
                                            // Phone number already exists
                                            alert(
                                                "Phone number already exists"
                                            );
                                            // Display an error message to the user or handle it accordingly
                                        } else {
                                            // Other errors
                                            console.error(
                                                "Error submitting transaction:",
                                                error
                                            );
                                        }
                                    }
                                };
                                postForm();
                            }}
                        >
                            <Form className="mt-3 form-horizontal col-md-8 mx-auto card no-bg border-secondary p-3">
                                <div className="card-body">
                                    {showAlert && (
                                        <AlertDismissible
                                            variant={"success"}
                                            alertMsg={
                                                "Record added successfully "
                                            }
                                        />
                                    )}
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">
                                            Agent Name
                                        </label>
                                        <div className="col-sm-10">
                                            <Field
                                                type="text"
                                                name="agentName"
                                                className="form-control"
                                            />
                                            <div className="text-danger">
                                                <ErrorMessage name="agentName" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">
                                            Phone Number
                                        </label>
                                        <div className="col-sm-10">
                                            <Field
                                                type="text"
                                                name="phoneNumber"
                                                className="form-control"
                                            />

                                            <div className="text-danger">
                                                <ErrorMessage name="phoneNumber" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">
                                            Address
                                        </label>
                                        <div className="col-sm-10">
                                            <Field
                                                type="textarea"
                                                name="address"
                                                className="form-control"
                                            />

                                            <div className="text-danger">
                                                <ErrorMessage name="address" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Link to="/agents">
                                                <Button className="btn btn-info form-control">
                                                    <i className="fas fa-eye"></i>{" "}
                                                    View Agents
                                                </Button>
                                            </Link>
                                        </div>
                                        <div className="col">
                                            <Button
                                                type="submit"
                                                className="btn btn-warning form-control"
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </section>
        </div>
    );
}
