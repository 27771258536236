import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import * as formik from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import AlertDismissible from "./Alert";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Receipt } from "../PDFs/Receipt";
import { Axios } from "../config";

export const BMETcardComponent = () => {
    const { Formik, ErrorMessage } = formik;
    const [data, setData] = useState([]);
    const [agents, setAgents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showAlert, setShowAlert] = useState(false);
    const [dueAmount, setDueAmount] = useState(0);

    useEffect(() => {
        const getAgents = async () => {
            try {
                const res = await Axios.get(`get-agents`);
                if (res.status === 200) {
                    setAgents(res.data);
                }
            } catch (err) {
                alert(err);
            }
        };
        getAgents();
        return () => {};
    }, []);

    const calculateDueAmount = (values) => {
        const received = parseFloat(values.receivedAmount);
        const total = parseFloat(values.totalAmount);

        if (!isNaN(received) && !isNaN(total)) {
            const due = total - received;
            setDueAmount(due); // Set due amount with 2 decimal places
        }
    };

    const handleDateChange = (date, { setFieldValue }) => {
        setSelectedDate(date);
        setFieldValue("departureDate", format(date, "dd/MM/yyyy"));
    };

    const currentDate = new Date();

    // Get day, month, and year components from currentDate
    const day = currentDate.getDate().toString().padStart(2, "0"); // Ensure two digits, padding with 0 if necessary
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so add 1
    const year = currentDate.getFullYear();

    // Construct the date string in the format dd/mm/yyyy
    const FDate = `${day}/${month}/${year}`;
    const formattedDate = currentDate
        .toISOString()
        .replace(/[-:.]/g, "")
        .replace("T", "_")
        .slice(0, -5);

    const fileName = `Receipt_${formattedDate}.pdf`;

    const schema = Yup.object().shape({
        agentName: Yup.string().required("Agent is required"),
        clientName: Yup.string().required("Customer name is required"),
        passport: Yup.string().required("Passport is required"),
        departureDate: Yup.string().required("Date is required"),
        totalAmount: Yup.number().required("Total amount is required"),
        receivedAmount: Yup.number().required("Received amount is required"),
        note: Yup.string(),
    });

    const generateRandomString = (length) => {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const numbers = "0123456789";
        const charactersLength = characters.length;
        const numbersLength = numbers.length;
        for (let i = 0; i < length; i++) {
            if (i < 2) {
                result += characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                );
            } else {
                result += numbers.charAt(
                    Math.floor(Math.random() * numbersLength)
                );
            }
        }
        return result;
    };
    return (
        <div>
            <Formik
                validationSchema={schema}
                onSubmit={(values, { resetForm }) => {
                    // Generate unique transaction ID
                    const transactionId = generateRandomString(6);
                    // Calculate the due amount
                    const received = parseFloat(values.receivedAmount);
                    const total = parseFloat(values.totalAmount);
                    const due =
                        !isNaN(received) && !isNaN(total)
                            ? total - received
                            : "";

                    // Include the due amount in the form values
                    const updatedValues = {
                        ...values,
                        dueAmount: due,
                        transactionId: transactionId,
                        createdAt: FDate,
                        purpose: "BMET",
                    };

                    const postForm = async () => {
                        try {
                            const res = await Axios.post(
                                `transaction-form-submit-BMET`,
                                updatedValues
                            );
                            if (res) {
                                // Show alert and reset form
                                setShowAlert(true);
                                setDueAmount(0);
                                resetForm();
                                setData([updatedValues]);
                            }
                            //console.log(res);
                        } catch (err) {
                            alert(err);
                        }
                    };
                    postForm();
                }}
                initialValues={{
                    transactionId: "",
                    agentName: "",
                    clientName: "",
                    passport: "",
                    departureDate: "",
                    totalAmount: "",
                    receivedAmount: "",
                    dueAmount: 0,
                    note: "",
                    createdAt: "",
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationFormik01"
                                className="position-relative"
                            >
                                <Form.Label>Agent Name</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="agentName"
                                    value={values.agentName}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled>
                                        Select Agent
                                    </option>
                                    {agents.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </Form.Control>
                                <div className="text-danger">
                                    <ErrorMessage name="agentName" />
                                </div>
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationFormik02"
                                className="position-relative"
                            >
                                <Form.Label>Client name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="clientName"
                                    value={values.clientName}
                                    onChange={handleChange}
                                />
                                <div className="text-danger">
                                    <ErrorMessage name="clientName" />
                                </div>
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationFormik02"
                                className="position-relative"
                            >
                                <Form.Label>Passport</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="passport"
                                    value={values.passport}
                                    onChange={handleChange}
                                />
                                <div className="text-danger">
                                    <ErrorMessage name="passport" />
                                </div>
                            </Form.Group>
                            
                        </Row>

                        <Row className="mb-3">
                            <Form.Group
                                as={Col}
                                md="2"
                                controlId="validationFormik04"
                                className="position-relative"
                            >
                                <Form.Label>Delivery(Approx)</Form.Label>
                                <DatePicker
                                    name="departureDate"
                                    selected={selectedDate}
                                    onChange={(date) =>
                                        handleDateChange(date, {
                                            setFieldValue,
                                        })
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control" // Add the form-control class here
                                    autoComplete="off"
                                />
                                <div className="text-danger">
                                    <ErrorMessage name="departureDate" />
                                </div>
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationFormik08"
                                className="position-relative"
                            >
                                <Form.Label>Total</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="totalAmount"
                                    value={values.totalAmount}
                                    onChange={(e) => {
                                        handleChange(e);
                                        calculateDueAmount({
                                            ...values,
                                            totalAmount: e.target.value,
                                        });
                                    }}
                                />
                                <div className="text-danger">
                                    <ErrorMessage name="totalAmount" />
                                </div>
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                md="4"
                                controlId="validationFormik09"
                                className="position-relative"
                            >
                                <Form.Label>Received</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="receivedAmount"
                                    value={values.receivedAmount}
                                    onChange={(e) => {
                                        handleChange(e);
                                        calculateDueAmount({
                                            ...values,
                                            receivedAmount: e.target.value,
                                        });
                                    }}
                                />
                                <div className="text-danger">
                                    <ErrorMessage name="receivedAmount" />
                                </div>
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                md="2"
                                controlId="validationFormik10"
                                className="position-relative"
                            >
                                <Form.Label>Due</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="dueAmount"
                                    value={dueAmount} // Display calculated due amount
                                    readOnly // Make the field read-only
                                />
                                <div className="text-danger">
                                    <ErrorMessage name="dueAmount" />
                                </div>
                            </Form.Group>
                        </Row>

                        <Form.Group
                            as={Col}
                            col={4}
                            md="12"
                            controlId="validationFormik11"
                            className="position-relative"
                        >
                            <Form.Label>Note (If Any)</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="note"
                                value={values.note}
                                onChange={handleChange}
                            />
                            <div className="text-danger">
                                <ErrorMessage name="note" />
                            </div>
                        </Form.Group>
                        {showAlert && (
                            <>
                                <div className="row">
                                    <div className="col-md-10">
                                        <AlertDismissible
                                            variant={"success"}
                                            alertMsg={
                                                "Record added successfully "
                                            }
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <PDFDownloadLink
                                            style={{
                                                marginLeft: "auto",
                                                fontSize: "15px",
                                            }}
                                            document={
                                                <Receipt Items={data} />
                                            }
                                            fileName={fileName} // Use the fileName variable here
                                        >
                                            {({ loading }) =>
                                                loading ? (
                                                    "Loading..."
                                                ) : (
                                                    <div className="mt-3">
                                                        {" "}
                                                        <i className="fas fa-download"></i>{" "}
                                                        Download Here
                                                    </div>
                                                )
                                            }
                                        </PDFDownloadLink>
                                    </div>
                                </div>
                            </>
                        )}
                        <Button
                            type="submit"
                            className="form-control btn btn-info mt-5"
                        >
                            Submit
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
