import React from "react";
import { Header } from "./Header";
import { Menu } from "./Menu";
import { TotaltranComponent } from "../Components/TotaltranComponent";
import { Footer } from "./Footer";

export const TotalTransactions = () => {
    return (
        <div className="wrapper">
            <Header />
            <Menu />
            <TotaltranComponent />
            <Footer />
        </div>
    );
};
